import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FileDownloadPage } from './pages/FileDownloadPage/FileDownloadPage';

export const Router = (): React.ReactElement => {
    return (
        <Routes>
            <Route path="/strata/:tenantIdentifier/:fileId" element={<FileDownloadPage />} />
        </Routes>
    );
};

export default Router;
