import React from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import { ApplicationContextProvider } from '@samc/doc-management';
import { useUserAuthenticationState } from '@samc/single-spa-authentication';
import { useTenantState } from '@samc/single-spa-tenant-selector';
import { HashHistory } from 'history';
import { Router } from './Router';

interface Props {
    hashHistory: HashHistory;
}

export const Root = ({ hashHistory }: Props): React.ReactElement => {
    const [gracePeriodActive, setGracePeriodActive] = React.useState(true);
    React.useEffect(() => {
        const timer = setTimeout(() => setGracePeriodActive(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    const [location, setLocation] = React.useState(hashHistory.location);
    React.useEffect(() => {
        return hashHistory.listen((l) => setLocation(l.location));
    }, [hashHistory]);

    const tenantState = useTenantState();

    const authState = useUserAuthenticationState();

    const token = authState?.accessToken?.value;

    if (!tenantState) return gracePeriodActive ? <></> : <div>tenantState is unexpectedly undefined.</div>;

    const headers = {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenantState.name,
    };

    return (
        <ReactRouter location={location} navigator={hashHistory}>
            <ApplicationContextProvider requestInit={{ headers }} TenantIdentifier={tenantState?.identifier}>
                <Router />
            </ApplicationContextProvider>
        </ReactRouter>
    );
};

export default Root;
