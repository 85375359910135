import React from 'react';
import { QueryClient as QueryClientClass, QueryClientProvider } from 'react-query';
import { post as _post } from '../../functions/post/post';
// eslint-disable-next-line import/no-cycle
import { useStrataFile as _useStrataFile } from '../../hooks/useStrataFile/useStrataFile';
const _strataApi = '/_apis/strata';
const _queryClient = new QueryClientClass();
export const ApplicationContext = React.createContext({
    post: _post,
    QueryClient: _queryClient,
    requestInit: undefined,
    StrataApi: _strataApi,
    TenantIdentifier: undefined,
    useStrataFile: _useStrataFile,
});
export const ApplicationContextProvider = ({ children, post = _post, QueryClient = _queryClient, requestInit, StrataApi = _strataApi, TenantIdentifier, useStrataFile = _useStrataFile, }) => {
    const value = React.useMemo(() => ({
        post,
        QueryClient,
        requestInit,
        StrataApi,
        TenantIdentifier,
        useStrataFile,
    }), [post, QueryClient, requestInit, StrataApi, TenantIdentifier, useStrataFile]);
    return (React.createElement(QueryClientProvider, { client: QueryClient },
        React.createElement(ApplicationContext.Provider, { value: value }, children)));
};
export default ApplicationContext;
