import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-cycle
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';
import { getStrataFileByIdAsync } from '../../functions/getStrataFileByIdAsync/getStrataFileByIdAsync';
import { downloadFile } from '../../functions/downloadFile/downloadFile';
import { downloadStrataFileByIdAsync } from '../../functions/downloadStrataFileByIdAsync/downloadStrataFileByIdAsync';
export const STRATA_GET_FILE_KEY = 'StrataGetFile';
export const STRATA_FILE_DOWNLOAD_KEY = 'StrataFileDownload';
export const useStrataFile = (fileId, tenantIdentifier) => {
    var _a, _b;
    const { requestInit, StrataApi } = useApplicationContext();
    const getFileQuery = useQuery([STRATA_GET_FILE_KEY, fileId], () => getStrataFileByIdAsync(StrataApi, tenantIdentifier, fileId, requestInit), { refetchOnWindowFocus: false, retry: false });
    const fileDownloadQuery = useQuery([STRATA_FILE_DOWNLOAD_KEY, (_a = getFileQuery.data) === null || _a === void 0 ? void 0 : _a.downloadLink], () => { var _a; return downloadStrataFileByIdAsync((_a = getFileQuery.data) === null || _a === void 0 ? void 0 : _a.downloadLink); }, {
        enabled: !!((_b = getFileQuery.data) === null || _b === void 0 ? void 0 : _b.downloadLink),
        onSuccess: (data) => {
            var _a;
            if (!data)
                return;
            downloadFile(data, (_a = getFileQuery.data) === null || _a === void 0 ? void 0 : _a.fileName);
        },
        refetchOnWindowFocus: false,
        retry: false,
    });
    return [getFileQuery, fileDownloadQuery];
};
export default useStrataFile;
