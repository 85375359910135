import React from 'react';
import { useApplicationContext } from '@samc/doc-management';
import { useParams } from 'react-router-dom';

export const FileDownloadPage = (): React.ReactElement => {
    const { useStrataFile } = useApplicationContext();

    const { fileId, tenantIdentifier } = useParams();

    const [getFileQuery, fileDownloadQuery] = useStrataFile(fileId, tenantIdentifier);

    if (getFileQuery.isLoading || fileDownloadQuery.isLoading) {
        return <div>Downloading file...</div>;
    }

    if (getFileQuery.isError || fileDownloadQuery.isError) {
        return <div>Error downloading file.</div>;
    }

    return (
        <div>
            Download successful. If your download doesn&apos;t start automatically, please{' '}
            <a href={getFileQuery.data?.downloadLink}>click here</a>.
        </div>
    );
};

export default FileDownloadPage;
