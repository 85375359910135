import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Root } from './root.component';

const lifecycles = singleSpaReact({
    renderType: 'createRoot',
    React,
    ReactDOM,
    rootComponent: Root,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your microfrontend here.
        return <></>;
    },
});

export const { bootstrap, mount, unmount } = lifecycles;
