/**
 * Prompts the browser to download the provided Blob as a file.
 */
export const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName !== null && fileName !== void 0 ? fileName : 'Export.xlsx';
    // creates an anchor to inject a filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export default downloadFile;
