var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const get = (input_1, init_1, ...args_1) => __awaiter(void 0, [input_1, init_1, ...args_1], void 0, function* (input, init, ignoreStatus = []) {
    const response = yield fetch(input, Object.assign({ method: 'GET' }, init));
    if (response.status >= 400 && response.status < 600 && ignoreStatus.indexOf(response.status) === -1) {
        const body = yield response.json();
        if (body.developerMessage) {
            throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${body.messages[0]}`);
        }
    }
    return response;
});
export default get;
