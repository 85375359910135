var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { post as _post } from '../post/post';
export const getStrataFileByIdAsync = (api_1, tenantIdentifier_1, fileId_1, requestInit_1, ...args_1) => __awaiter(void 0, [api_1, tenantIdentifier_1, fileId_1, requestInit_1, ...args_1], void 0, function* (api, tenantIdentifier, fileId, requestInit, post = _post) {
    if (!fileId || !tenantIdentifier)
        return undefined;
    const url = `${api}/api/${tenantIdentifier}/manager/v1/files/${fileId}/_download`;
    const response = yield post(url, {
        headers: requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers,
    });
    return response.json();
});
export default getStrataFileByIdAsync;
